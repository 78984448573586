<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-chip
        v-for="(monthEquip, idx) in selectedRow.equipmentMaintainMonthList"
        :key="idx"
        :color="monthEquip.color"
        text-color="white"
        icon="bookmark"
        :selected.sync="monthEquip.selected"
        id="chip"
        size="18px"
        :label="monthEquip.month"
        :title="monthEquip.month"
        v-model="monthEquip.check"
        @update:selected="state => selectedEquipment(state, monthEquip, idx)"
      >
      </q-chip>
      <!-- <q-chip
        clickable
        color="orange"
        text-color="white"
        icon="add"
        label="추가"
        title="추가"
        @click="addProcess"
      /> -->
    </div>
    <div class="col-12">
      <template>
        <component
          :is="component"
          :selectedRow2.sync="selectedRow2"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'equipment-record-by-year',
  props: {
    selectedRow: {
      type: Object,
      default: () => ({
        year: '',
        equipmentMaintainMonthList: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      selectedRow2: {
        equipmentMaintainList: [],
        month: "",
        year: "",
      },
      component: null,
      isCheck: false,
      getUrl: '',
    };
  },
  watch: {
    'selectedRow'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$_.forEach(this.selectedRow.equipmentMaintainMonthList, _item => {
        _item.check = true;
        _item.selected = false;
        _item.color = 'grey-6';
      })
      this.selectedEquipment(true, this.selectedRow.equipmentMaintainMonthList[0], 0)
      this.component = () => import('./equipmentRecordDetail.vue');
    },
    selectedEquipment(state, monthEquip, idx) {
      if (state) {
        this.selectedRow.equipmentMaintainMonthList.forEach((item, index) => {
          if (idx !== index) {
            item.selected = false;
            item.color = 'grey-6'
          } else {
            item.selected = true;
            item.color = 'green'
            this.selectedRow2 = monthEquip;
          }
        })
      } else {
        this.$_.forEach(this.selectedRow.equipmentMaintainMonthList, item => {
          item.selected = false;
          item.color = 'grey-6'
        })
      }
    },
  }
};
</script>
<style>
#chip {
  font-size: 15px !important;
  margin-left: 5px
}
</style>